import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../common/header";
// import Feature from "../lending/feature";
import { ProjectContext } from "../../ProjectContext";
import Faq from "./faq";

function Index() {
  const [amount, setamount] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const referrals = query.get('referral');
  
  const {
    copyaddress,
    account,
    toastSuccess,
    library,
    toastError,
    settings,
    esigner,
    ethers,
    connectMetamask,
    balance,
  } = useContext(ProjectContext);

  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }

    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );

    async function main() {
      try {
        var stk = await contract.stake(
          ethers.utils.parseEther(`${amount}`),
          referral
        );

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        toastSuccess("Transaction done");
        settrdone(false);
      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }
    main();
  };
  useEffect(() => {
    setreferral(link_ref_address);
  }, [link_ref_address]);

  return (
    <div id="wrapper">
      <Header />
      <section className="page-title st3">
        <div className="overlay" />

        <div className="swiper-container slider-main">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="slider-st3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="box-slider">
                        <div className="content-box">
                          <h1 className="title">$ASTRD Airdrop</h1>
                          <p className="sub-title">
                            Claim your 500$ASTRD Token worth $32
                          </p>
                          {account ? (
                            <p className="sub-title">
                              <h6>🟢{account}</h6>
                              <h6>Balance: {balance} ASTRD</h6>
                            </p>
                          ) : (
                            ""
                          )}
                          {account ? (
                            <div className="wrap-btn">
                              <a
                                type="button"
                                onClick={() => onDeposit()}
                                className="tf-button style2"
                              >
                                Claim Now🎉
                              </a>
                              &nbsp;
                              <a
                                type="button"
                                onClick={() =>
                                  copyaddress(
                                    `https://asteroidtoken.cloud/ref/${account}`
                                  )
                                }
                                className="tf-button style4"
                              >
                                Copy Referral Link🔗
                              </a>
                            </div>
                          ) : (
                            <div className="wrap-btn">
                              <a
                                type="button"
                                onClick={() => connectMetamask()}
                                className="tf-button style2"
                              >
                                Connect Now
                              </a>
                            </div>
                          )}
                          <div className="flat-box">
                            <div className="box-couter">
                              <p>Potential project</p>
                              <div className="number-content">
                                <h6 className="count-number2">700+</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Market cap</p>
                              <div className="number-content">
                                <h6 className="count-number2">$570.24 M</h6>
                              </div>
                            </div>
                            <div className="box-couter">
                              <p>Investors</p>
                              <div className="number-content">
                                <h6 className="count-number2">697</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="image">
                          <img
                            className="img_main"
                            src="/assets/images/common/img_slider3.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Feature /> */}
      <Faq />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
export default Index;

import React, { useState } from "react";

function Faq() {
  return (
    <section className="tf-section FAQs">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration={800}
            >
              <h2 className="title">frequently asked questions</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="flat-accordion aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration={800}
            >
              <div className="flat-toggle">
                <div className="h7 toggle-title">
                  <span className="icon-Icon"> </span>
                  <span>What is the metaverse?</span>
                </div>
                <p className="toggle-content" style={{ display: "none" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
              <div className="flat-toggle ">
                <div className="h7 toggle-title ">
                  <span className="icon-Icon"> </span>
                  <span>How to access the metaverse?</span>
                </div>
                <p className="toggle-content" >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
              <div className="flat-toggle">
                <div className="h7 toggle-title">
                  <span className="icon-Icon"> </span>
                  <span>How do you make purchases in the metaverse?</span>
                </div>
                <p className="toggle-content" style={{ display: "none" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
              <div className="flat-toggle">
                <div className="h7 toggle-title">
                  <span className="icon-Icon"> </span>
                  <span>Is the metaverse fun?</span>
                </div>
                <p className="toggle-content" style={{ display: "none" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
              <div className="flat-toggle">
                <div className="h7 toggle-title">
                  <span className="icon-Icon"> </span>
                  <span>
                    How do I find things to actually do in the metaverse?
                  </span>
                </div>
                <p className="toggle-content" style={{ display: "none" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
              <div className="flat-toggle">
                <div className="h7 toggle-title">
                  <span className="icon-Icon"> </span>
                  <span>What is ownership in the metaverse?</span>
                </div>
                <p className="toggle-content" style={{ display: "none" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  luctus eleifend egestas. Duis eget dictum purus. Fusce est mi,
                  imperdiet porta neque malesuada, blandit scelerisque lectus.
                  Maecenas vel lobortis risus. Donec ornare urna quis libero
                  euismod, ac fermentum diam blandit. Nunc dapibus tempor lacus,
                  vel lacinia enim efficitur eu
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="content_faq text-center">
              <p>If you don`t find an answer, contact with our experts</p>
              <div className="wrap-btn">
                <a href="contact.html" className="tf-button style1">
                  get in touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Faq;
